<template>
  <div class="Main">
    <div class="Content">
      <div class="Box">
        <div class="User">
          <div class="User_wallpaper_wrapper">
            <img
              class="User_wallpaper"
              v-bind:src="this.user.cover_image"
              v-bind:data-src="this.user.cover_image"
            />
          </div>
          <div class="User_profile">
            <div class="User_profile_detailed_parent">
              <div class="User_profile_detailed_left">
                <div class="User_profile_image_wrapper">
                  <img
                    class="User_profile_image"
                    v-bind:src="this.user.profile_icon"
                    v-bind:data-src="this.user.profile_icon"
                  />
                </div>
                <div class="Nickname">
                  <div class="Nickname_wrapper">
                    <span class="Nickname_span">{{ this.user.nickname }}</span>
                  </div>
                </div>
                <div class="Username">
                  <div class="Username_wrapper">
                    <span class="Username_span">{{ this.user.username }}</span>
                  </div>
                </div>
              </div>
              <div class="User_profile_detailed_right">
                <div class="detailed_right_primary">
                  <div class="detailed_gender detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-gender-male-female
                      </v-icon>
                      性別
                    </p>
                    <span class="detailed_content">{{ this.user.gender }}</span>
                  </div>
                  <div class="detailed_generation detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-school
                      </v-icon>
                      年代
                    </p>
                    <span class="detailed_content">{{
                      this.user.generation
                    }}</span>
                  </div>
                  <div class="detailed_new_user detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-new-box
                      </v-icon>
                      新規ユーザー
                    </p>
                    <span class="detailed_content">{{
                      this.user.new_user
                    }}</span>
                  </div>
                  <div class="detailed_title detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-check-decagram
                      </v-icon>
                      承認アカウント
                    </p>
                    <span class="detailed_content">{{ this.user.title }}</span>
                  </div>
                  <div
                    class="detailed_login_streak_count detailed_body_wrapper"
                  >
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-login
                      </v-icon>
                      連続ログイン日数
                    </p>
                    <span class="detailed_content"
                      >{{ this.user.login_streak_count }}日</span
                    >
                  </div>
                  <div class="detailed_age_verified detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-account-check
                      </v-icon>
                      年齢確認
                    </p>
                    <span class="detailed_content">{{
                      this.user.age_verified
                    }}</span>
                  </div>
                  <div class="detailed_mobile_verified detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-phone-check
                      </v-icon>
                      電話番号認証
                    </p>
                    <span class="detailed_content">{{
                      this.user.mobile_verified
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="detailed_right_secondary"
                  class="detailed_right_secondary"
                >
                  <div class="detailed_is_online detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-list-status
                      </v-icon>
                      オンライン設定
                    </p>
                    <span class="detailed_content">{{
                      this.user.is_online
                    }}</span>
                  </div>
                  <div class="detailed_private detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-account-key
                      </v-icon>
                      フォロー許可制
                    </p>
                    <span class="detailed_content">{{
                      this.user.is_private
                    }}</span>
                  </div>
                  <div class="detailed_mutual_chat detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-chat-plus
                      </v-icon>
                      相互のみチャット受け取り
                    </p>
                    <span class="detailed_content">{{
                      this.user.mutual_chat
                    }}</span>
                  </div>
                  <div class="detailed_chat_request detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-chat-plus
                      </v-icon>
                      フォロー中のみチャット受け取り
                    </p>
                    <span class="detailed_content">{{
                      this.user.chat_request
                    }}</span>
                  </div>
                  <div
                    class="detailed_chat_required_phone_verification detailed_body_wrapper"
                  >
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-chat-plus
                      </v-icon>
                      電話番号認証済みの人のみチャット受け取り
                    </p>
                    <span class="detailed_content">{{
                      this.user.chat_required_phone_verification
                    }}</span>
                  </div>
                  <div
                    class="detailed_age_restricted_on_review detailed_body_wrapper"
                  >
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-email-receive
                      </v-icon>
                      年齢の近い人のみレター受け取り
                    </p>
                    <span class="detailed_content">{{
                      this.user.age_restricted_on_review
                    }}</span>
                  </div>
                  <div
                    class="detailed_following_restricted_on_review detailed_body_wrapper"
                  >
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-email-receive
                      </v-icon>
                      フォロー中の人のみレター受け取り
                    </p>
                    <span class="detailed_content">{{
                      this.user.following_restricted_on_review
                    }}</span>
                  </div>
                  <div
                    class="detailed_restricted_review_by detailed_body_wrapper"
                  >
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-email-send
                      </v-icon>
                      レター制限
                    </p>
                    <span class="detailed_content">{{
                      this.user.restricted_review_by
                    }}</span>
                  </div>
                  <div class="detailed_recently_kenta detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-cancel
                      </v-icon>
                      一ヶ月以内のゴミ虫歴
                    </p>
                    <span class="detailed_content">{{
                      this.user.recently_kenta
                    }}</span>
                  </div>
                  <div class="detailed_dangerous_user detailed_body_wrapper">
                    <p class="detailed_title">
                      <v-icon small>
                        mdi-account-alert
                      </v-icon>
                      危険なユーザー
                    </p>
                    <span class="detailed_content">{{
                      this.user.dangerous_user
                    }}</span>
                  </div>
                </div>
                <v-btn
                  v-if="!detailed_right_secondary"
                  @click="detailed_right_secondary = !detailed_right_secondary"
                >
                  もっと見る
                </v-btn>
              </div>
            </div>
            <p class="User_profile_p">
              {{ this.user.biography }}
            </p>
            <div class="User_profile_field_last">
              <div class="User_page_link">
                <v-icon small>
                  mdi-link-variant
                </v-icon>
                <a
                  v-bind:href="'https://yay.space/user/' + this.user.id"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  yay.space/user/{{ this.user.id }}
                </a>
              </div>
              <div class="User_creating_date">
                <v-icon small>
                  mdi-calendar-account
                </v-icon>
                <p class="creating_date">
                  {{ this.user.created_at }}からYayを利用しています
                </p>
              </div>
            </div>
            <dl class="User_profile_dl">
              <div class="User_profile_dl_item">
                <a
                  class="User_profile_dl_item_a"
                  v-bind:href="'https://yay.space/user/' + this.user.id"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <dt>投稿</dt>
                  <dd>{{ this.user.posts_count }}</dd>
                </a>
              </div>
              <div class="User_profile_dl_item">
                <a
                  class="User_profile_dl_item_a"
                  v-bind:href="
                    'https://yay.space/user/' + this.user.id + '/reviews'
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <dt>レター</dt>
                  <dd>{{ this.user.reviews_count }}</dd>
                </a>
              </div>
              <div class="User_profile_dl_item">
                <a
                  class="User_profile_dl_item_a"
                  v-bind:href="
                    'https://yay.space/user/' + this.user.id + '/groups'
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <dt>サークル</dt>
                  <dd>{{ this.user.groups_users_count }}</dd>
                </a>
              </div>
              <div class="User_profile_dl_item">
                <a
                  class="User_profile_dl_item_a"
                  v-bind:href="
                    'https://yay.space/user/' + this.user.id + '/followers'
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <dt>フォロワー</dt>
                  <dd>{{ this.user.followers_count }}</dd>
                </a>
              </div>
              <div class="User_profile_dl_item">
                <a class="User_profile_dl_item_a">
                  <dt>フォロー</dt>
                  <dd>{{ this.user.followings_count }}</dd>
                </a>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="Box">
        <div class="ConfirmBox__users">
          <div class="UserList">
            <div
              v-for="(item, key) in followings"
              :key="key"
              class="UserList__item"
            >
              <a v-bind:href="'/profile?id=' + item.id" target="_blank">
                <div class="UserList__item__image_wrapper">
                  <div
                    class="Avatar__wrapper"
                    v-lazy-container="{
                      selector: 'img',
                      error:
                        'https://storage.googleapis.com/bexix-c3ac1.appspot.com/placeholder-profile.svg',
                      loading:
                        'https://storage.googleapis.com/bexix-c3ac1.appspot.com/placeholder-profile.svg',
                    }"
                  >
                    <img class="Avatar" v-bind:data-src="item.profile_icon" />
                  </div>
                </div>
              </a>
              <a
                class="UserList__item__name_wrapper"
                v-bind:href="'/profile?id=' + item.id"
                target="_blank"
              >
                <div class="Nickname">
                  <div class="Nickname__wrapper">
                    <span class="Nickname__span">{{ item.nickname }}</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <a class="LoadMore">もっと見る</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      user: {
        id: null,
        username: "none",
        nickname: null,
        prefecture: null,
        biography: null,
        followers_count: null,
        followings_count: null,
        gender: "未設定",
        is_private: null,
        title: "なし",
        posts_count: null,
        groups_users_count: null,
        reviews_count: null,
        login_streak_count: null,
        age_verified: null,
        generation: null,
        is_online: null,
        online_status: null,
        profile_icon: null,
        cover_image: null,
        created_at: "XXXX年XX月XX日",
        vip: null,
        mutual_chat: null,
        chat_request: null,
        chat_required_phone_verification: null,
        age_restricted_on_review: null,
        following_restricted_on_review: null,
        restricted_review_by: null,
        mobile_verified: null,
        recently_kenta: null,
        dangerous_user: null,
        new_user: null,
      },
      detailed_right_secondary: false,
      followings: [],
    };
  },
  created: function() {
    this.axios
      .get(
        "https://cors.bexix.net/https://api.yay.space/v2/users/" +
          this.$route.query.id
      )
      .then((response) => {
        this.user.id = response.data.user.id;
        this.user.username = response.data.user.username;
        this.user.nickname = response.data.user.nickname;
        this.user.prefecture = response.data.user.prefecture;
        this.user.biography = response.data.user.biography;
        this.user.followers_count = response.data.user.followers_count;
        this.user.followings_count = response.data.user.followings_count;
        if (response.data.user.gender == "0") {
          this.user.gender = "男性";
        } else if (response.data.user.gender == "1") {
          this.user.gender = "女性";
        }
        if (response.data.user.is_private == true) {
          this.user.is_private = "有効";
        } else if (response.data.user.is_private == false) {
          this.user.is_private = "無効";
        }
        if (response.data.user.title == "admin") {
          this.user.title = "運営";
        } else if (response.data.user.title == "official") {
          this.user.title = "公式";
        }
        this.user.posts_count = response.data.user.posts_count;
        this.user.groups_users_count = response.data.user.groups_users_count;
        this.user.reviews_count = response.data.user.reviews_count;
        this.user.login_streak_count = response.data.user.login_streak_count;
        if (response.data.user.age_verified == true) {
          this.user.age_verified = "確認済み";
        } else if (response.data.user.age_verified == false) {
          this.user.age_verified = "未確認";
        }
        if (response.data.user.generation == 0) {
          this.user.generation = "中学生以下";
        } else if (response.data.user.generation == 1) {
          this.user.generation = "高校生";
        } else if (response.data.user.generation == 2) {
          this.user.generation = "大学生";
        } else if (response.data.user.generation == 3) {
          this.user.generation = "成人";
        } else {
          this.user.generation = "不明";
        }
        if (response.data.user.is_online == true) {
          this.user.is_online = "公開";
        } else if (response.data.user.is_online == false) {
          this.user.is_online = "非公開";
        }
        this.user.online_status = response.data.user.online_status;
        this.user.profile_icon = response.data.user.profile_icon;
        this.user.cover_image = response.data.user.cover_image;
        var dd = new Date(response.data.user.created_at * 1000);
        var YYYY = dd.getFullYear();
        var MM = dd.getMonth() + 1;
        var DD = dd.getDate();
        this.user.created_at = YYYY + "年" + MM + "月" + DD + "日";
        this.user.vip = response.data.user.vip;
        if (response.data.user.mutual_chat == true) {
          this.user.mutual_chat = "有効";
        } else if (response.data.user.mutual_chat == false) {
          this.user.mutual_chat = "無効";
        }
        if (response.data.user.chat_request == true) {
          this.user.chat_request = "有効";
        } else if (response.data.user.chat_request == false) {
          this.user.chat_request = "無効";
        }
        if (response.data.user.chat_required_phone_verification == true) {
          this.user.chat_required_phone_verification = "有効";
        } else if (
          response.data.user.chat_required_phone_verification == false
        ) {
          this.user.chat_required_phone_verification = "無効";
        }
        if (response.data.user.age_restricted_on_review == true) {
          this.user.age_restricted_on_review = "有効";
        } else if (response.data.user.age_restricted_on_review == false) {
          this.user.age_restricted_on_review = "無効";
        }
        if (response.data.user.following_restricted_on_review == true) {
          this.user.following_restricted_on_review = "有効";
        } else if (response.data.user.following_restricted_on_review == false) {
          this.user.following_restricted_on_review = "無効";
        }
        if (response.data.user.restricted_review_by == true) {
          this.user.restricted_review_by = "あり";
        } else if (response.data.user.restricted_review_by == false) {
          this.user.restricted_review_by = "なし";
        }
        if (response.data.user.mobile_verified == true) {
          this.user.mobile_verified = "認証済み";
        } else if (response.data.user.mobile_verified == false) {
          this.user.mobile_verified = "未認証";
        }
        if (response.data.user.recently_kenta == true) {
          this.user.recently_kenta = "あり";
        } else if (response.data.user.recently_kenta == false) {
          this.user.recently_kenta = "なし";
        }
        if (response.data.user.dangerous_user == true) {
          this.user.dangerous_user = "該当";
        } else if (response.data.user.dangerous_user == false) {
          this.user.dangerous_user = "なし";
        }
        if (response.data.user.new_user == true) {
          this.user.new_user = "該当";
        } else if (response.data.user.new_user == false) {
          this.user.new_user = "なし";
        }
      })
      .catch(() => {
        this.$router.push({
          name: "NotFound",
        });
      });

    this.axios
      .get(
        "https://cors.bexix.net/https://api.yay.space/v2/users/" +
          this.$route.query.id +
          "/web_followings?number=50"
      )
      .then((response) => {
        this.followings = response.data.users;
      })
      .catch(() => {
        this.$router.push({
          name: "NotFound",
        });
      });
  },
};
</script>

<style>
.Main {
  margin: 30px 0;
}

a {
  text-decoration: none;
}

.Content {
  max-width: 900px;
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .Content {
    width: 90%;
  }
}

.Box {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
  border-radius: 10px;
}

.Box:not(:last-child) {
  margin-bottom: 20px;
}

.User_wallpaper_wrapper {
  position: relative;
}

.User_wallpaper {
  width: 100%;
  height: 270px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  background-color: #757575;
}

.User_profile {
  margin-top: calc(135px / -2);
  padding: 20px;
  text-align: center;
}

.User_profile_detailed_parent {
  display: flex;
}

.User_profile_detailed_right {
  margin-top: 77.5px;
  margin-left: 20px;
}

.detailed_right_primary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.detailed_right_secondary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.detailed_body_wrapper {
  display: flex;
  margin: 0 12.5px;
}

.detailed_body_wrapper .detailed_title {
  color: #fff;
  background-color: #999999;
  border-radius: 3px;
  padding: 2px 7px;
  font-size: 12px;
}

@media (max-width: 1000px) {
  .detailed_body_wrapper .detailed_title {
    font-size: 1vw;
  }
}

.detailed_body_wrapper .detailed_content {
  font-size: 14px;
  font-weight: 700;
  margin-left: 15px;
  padding-top: 1px;
}

@media (max-width: 1000px) {
  .detailed_body_wrapper .detailed_content {
    font-size: 1.2vw;
    margin-left: 10px;
  }
}

.User_profile_detailed_left {
  text-align: center;
}

.User_profile_image_wrapper {
  box-shadow: 0 1px 12px 0 rgb(0 0 0 / 18%);
  padding: 5px;
  background-color: #fff;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .User_profile_image_wrapper {
    width: 16vw;
    height: 16vw;
  }
}

.User_profile .User_profile_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
}

.User_profile .Nickname {
  font-weight: bold;
  font-size: 25px;
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .User_profile .Nickname {
    font-size: 2.5vw;
  }
}

@media (max-width: 700px) {
  .User_profile .Nickname {
    font-size: 3.7vw;
  }
}

.User_profile .Nickname_wrapper {
  display: inline-grid;
  align-items: center;
  width: 135px;
}

.User_profile .Nickname_span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.User_profile .Username {
  font-size: 15px;
  color: #8899a6;
}

@media (max-width: 1000px) {
  .User_profile .Username {
    font-size: 1.7vw;
  }
}

@media (max-width: 700px) {
  .User_profile .Username {
    font-size: 2vw;
  }
}

.User_profile .Username_wrapper {
  display: inline-grid;
  align-items: center;
  width: 135px;
}

.User_profile .Username_span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.User_profile .User_profile_p {
  font-size: 13px;
  letter-spacing: 0;
  text-align: center;
  margin: 25px 0 !important;
  padding: 0 20px;
  white-space: pre-line;
}

.User_profile_field_last {
  display: flex;
  text-align: left;
  padding: 0 20px;
  font-size: 15px;
  color: #8899a6;
}

@media (max-width: 1000px) {
  .User_profile_field_last {
    font-size: 1.5vw;
  }
}

.User_page_link {
  padding-bottom: 1px;
}

.User_page_link .v-icon {
  padding-top: 2.5px;
}

.User_creating_date {
  display: flex;
  padding: 0 30px;
}

.User_creating_date .v-icon {
  padding-bottom: 3px;
}

.creating_date {
  margin: 0 0 !important;
  padding: 0 5px;
}

.User_profile_dl {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 30px;
}

.User_profile_dl_item:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}

.User_profile_dl_item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.User_profile_dl_item_a {
  display: block;
}

.User_profile_dl_item dt {
  font-size: 15px;
  font-weight: bold;
  color: #212121;
}

.User_profile_dl_item dd {
  margin-top: 10px;
  font-size: 15px;
  color: #212121;
}

.UserList__item {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 10px;
  padding: 15px;
  align-items: center;
  position: relative;
}

.UserList__item:not(:last-child) {
  border-bottom: solid 1px #e0e0e0;
}

.UserList__item .Avatar {
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.UserList__item .Nickname__span {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  color: #212121 !important;
}

.LoadMore {
  text-align: center;
  font-size: 12px;
  color: #64686b !important;
  display: block;
  padding: 20px;
}
</style>
